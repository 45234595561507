import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { GovernanceState } from './types';
import {
    getGovernance,
    getGovernanceSuccess,
    getGovernanceFailure,
    getGovernanceIdle,
} from './actions';

export const initialState: GovernanceState = {
    status: 'IDLE',
    about: null,
};

const status = handleActions<StateStatus>(
    {
        [getGovernance]: () => 'LOADING',
        [getGovernanceSuccess]: () => 'SUCCESS',
        [getGovernanceFailure]: () => 'FAILURE',
        [getGovernanceIdle]: () => 'IDLE',
    },
    initialState.status
);

const governance = handleActions(
    {
        [getGovernanceSuccess]: (
            state,
            action: { payload: { governance: {} } }
        ) => action.payload.governance
    },
    {}
);


export const governanceReducer = combineReducers({
    status,
    governance,
});
