import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

type tProps = {
    lang: string;
};

export const getHeaderRequest = (props: tProps) => {

    let locale: string = "";
    if (props && props.lang) {
        const lang: string = props.lang;
        if (lang) {
            locale += "&locale=" + lang;
        }
    }

    const requestUrl =
        apiUrl +
        'header/'+
        '?populate[0]=Logo' +
        '&populate[1]=Logo.LogoWhiteGreen' +
        '&populate[2]=Logo.LogoWhiteBlack' +
        '&populate[3]=Logo.LogoGreenBlack' +
        '&populate[4]=Menu' +
        '&populate[5]=Menu.SubmenuItems' +
        '&populate[6]=Contacts' +
        locale;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Strapi-Response-Format': 'v4',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
