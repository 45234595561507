import React, { useEffect, useState } from "react";
import { imgUrl, siteUrl } from "../../config/config";
import "./ScrollUp.scss";

export function ScrollUp() {

    const [showTopBtn, setShowTopBtn] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
        {showTopBtn && (
            <div className="scroll-up" onClick={goToTop}>
                <img src={imgUrl + "chevron-bar-up.svg"} alt="" />
            </div>
        )}
        </>
    );
}
