import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { PostState } from './types';
import {
    getPost,
    getPostSuccess,
    getPostFailure,
    getPostIdle,
} from './actions';

export const initialState: PostState = {
    status: 'IDLE',
    post: null,
};

const status = handleActions<StateStatus>(
    {
        [getPost]: () => 'LOADING',
        [getPostSuccess]: () => 'SUCCESS',
        [getPostFailure]: (state) => state === 'LOADING' ? 'FAILURE' : state,
        [getPostIdle]: () => 'IDLE',
    },
    initialState.status
);

const post = handleActions(
    {
        [getPost]: (state) => state,
        [getPostSuccess]: (state, action: { payload: { post: {} } }) => action.payload.post,
        [getPostFailure]: (state) => state,  // Keep existing state on failure
    },
    initialState.post
);

export const postReducer = combineReducers({
    status,
    post,
});
