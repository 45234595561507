import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { PostState } from './types';
import {
    getPost,
    getPostSuccess,
    getPostFailure,
    getPostIdle,
} from './actions';

export const initialState: PostState = {
    status: 'IDLE',
    post: null,
};

const status = handleActions<StateStatus>(
    {
        [getPost]: () => 'LOADING',
        [getPostSuccess]: () => 'SUCCESS',
        [getPostFailure]: () => 'FAILURE',
        [getPostIdle]: () => 'IDLE',
    },
    initialState.status
);

const post = handleActions(
    {
        [getPostSuccess]: (
            state,
            action: { payload: { post: {} } }
        ) => action.payload.post,
    },
    initialState.post
);


export const postReducer = combineReducers({
    status,
    post,
});
