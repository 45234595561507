import { call, takeEvery, put } from 'redux-saga/effects';

import {
    getProduct,
    getProductSuccess,
    getProductFailure,
    getProductIdle,
} from './actions';
import { getProductRequest } from './api';
//import { ActivitiesReduxType } from "./types";
import {
    tStrapiSingleDataResponse,
    tStrapiResposeError,
} from '../../../types/strapi.models';

function* getSaga(action: { payload: any }) {
    const { payload } = action;
    try {
        const productResult:
            | tStrapiSingleDataResponse
            | tStrapiResposeError = yield call(getProductRequest, payload);
        if (productResult && productResult.error) {
            yield put(
                getProductFailure({
                    error: productResult.error,
                })
            );
        } else {
            yield put(
                getProductSuccess({
                    product: productResult,
                    //meta: policyResult.meta,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(getProductFailure(error));
    }
}

export function* productWatcher() {
    yield takeEvery(getProduct.toString(), getSaga);
}
