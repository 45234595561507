import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

type tProps = {
    lang: string;
    slug: string;
};

export const getProductRequest = (props: tProps) => {

    const slug: string = props.slug;
    let filter:string = "";
    if (slug) {
        filter += "&filters[Slug][$eq]=" + slug;
    }

    let locale: string = "";
    if (props && props.lang) {
        const lang: string = props.lang;
        if (lang) {
            locale += "&locale=" + lang;
        }
    }

    const requestUrl =
        apiUrl +
        'single-products/' +
        '?populate[0]=ProductCards' +
        '&populate[1]=ProductCards.CardOption' +
        '&populate[2]=Image' +
        '&populate[3]=Reference' +
        '&populate[4]=ProductCards.Image' +
        filter +
        locale;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Strapi-Response-Format': 'v4',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => {
            return data.data[0];
        });
};
