import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { ProductsState } from './types';
import {
    getProducts,
    getProductsSuccess,
    getProductsFailure,
    getProductsIdle,
} from './actions';

export const initialState: ProductsState = {
    status: 'IDLE',
    products: null,
};

const status = handleActions<StateStatus>(
    {
        [getProducts]: () => 'LOADING',
        [getProductsSuccess]: () => 'SUCCESS',
        [getProductsFailure]: () => 'FAILURE',
        [getProductsIdle]: () => 'IDLE',
    },
    initialState.status
);

const products = handleActions(
    {
        [getProductsSuccess]: (
            state,
            action: { payload: { products: {} } }
        ) => action.payload.products
    },
    {}
);

export const productsReducer = combineReducers({
    status,
    products,
});
