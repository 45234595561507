import { call, takeEvery, put } from 'redux-saga/effects';

import {
    getProducts,
    getProductsSuccess,
    getProductsFailure,
    getProductsIdle,
} from "./actions";
import {
    getProductsRequest,
} from "./api";
//import { ActivitiesReduxType } from "./types";
import { tStrapiSingleDataResponse } from '../../../types/strapi.models';

function* getSaga(action: { payload:any }) {
    const { payload } = action;
    try {
        const productsResult: tStrapiSingleDataResponse = yield call(
            getProductsRequest,
            payload,
        );
        if (productsResult && productsResult.status === "error") {
            yield put(
                getProductsFailure({
                    error: productsResult.error,
                })
            );
        } else {
            yield put(
                getProductsSuccess({
                    products: productsResult.data,
                    //meta: policyResult.meta,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(getProductsFailure(error));
    }
}

export function* productsWatcher() {
    yield takeEvery(getProducts.toString(), getSaga);
}
