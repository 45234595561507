import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

type tProps = {
    lang: string;
};

export const getProductsRequest = (props: tProps) => {

    let locale: string = "";
    if (props && props.lang) {
        const lang: string = props.lang;
        if (lang) {
            locale += "&locale=" + lang;
        }
    }

    const requestUrl =
        apiUrl +
        'products/' +
        '?populate[0]=Services' +
        '&populate[1]=Services.ServicesList' +
        '&populate[2]=Services.ServicesList.Icon' +
        '&populate[3]=OtherProducts' +
        '&populate[4]=OtherProducts.OtherProductsList' +
        '&populate[5]=Contacts' +
        '&populate[6]=ProductsList' +
        '&populate[7]=ProductsList.Image' +
        locale;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Strapi-Response-Format': 'v4',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
