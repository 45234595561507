import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

type tProps = {
    lang: string;
    slug: string;
};

export const getPostRequest = (props: tProps) => {

    const { slug } = props;

    let filter: string = "";
    if (slug) {
        filter += "&filters[Slug][$eq]=" + slug;
    }

    let locale: string = "";
    if (props && props.lang) {
        const lang: string = props.lang;
        if (lang) {
            locale += "&locale=" + lang;
        }
    }

    const url =
        apiUrl +
        "news-list/" +
        '?populate[0]=Image' +
        '&populate[1]=PostBody.Image' +
        '&populate[2]=PostBody.AuthorPhoto' +
        '&populate[3]=PostBody.Video' +
        '&populate[4]=Reference' +
        filter +
        locale;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Strapi-Response-Format': 'v4',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(async response => {
            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            if (!data.data || !data.data[0]) {
                throw new Error('No data found');
            }
            
            return data.data[0];
        })
        .catch(error => {
            return { error: error.message };
        });
};
