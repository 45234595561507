import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

export const getAboutRequest = () => {
    const requestUrl =
        apiUrl +
        'about-us/?populate=Stats,Stats.Icon,Text,Leaders,Leaders.Image,Leaders.Leaders,Leaders.Leaders.Photo,Leaders.Link,Team,Image,Team.Text';

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
