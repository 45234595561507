import { call, takeLatest, put, race, take } from 'redux-saga/effects';

import {
    getPost,
    getPostSuccess,
    getPostFailure,
    getPostIdle,
} from './actions';
import { getPostRequest } from './api';
//import { ActivitiesReduxType } from './types';
import {
    tStrapiSingleDataResponse,
    tStrapiResposeError,
} from '../../../types/strapi.models';

function* getSaga(action: { payload: any }) {
    const { payload } = action;
    try {
        // Use race to handle timeout
        const { result, timeout } = yield race({
            result: call(getPostRequest, payload),
            timeout: take(getPost.toString())
        });

        if (timeout) {
            // If we get another getPost action, cancel this one
            return;
        }

        if ('error' in result) {
            yield put(
                getPostFailure({
                    error: result.error,
                })
            );
        } else {
            yield put(
                getPostSuccess({
                    post: result,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(getPostFailure(error));
    }
}

export function* postWatcher() {
    yield takeLatest(getPost.toString(), getSaga);
}
