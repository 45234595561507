import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { HomepageState } from './types';
import {
    getHomepage,
    getHomepageSuccess,
    getHomepageFailure,
    getHomepageIdle,
} from './actions';

export const initialState: HomepageState = {
    status: 'IDLE',
    homepage: null,
};

const status = handleActions<StateStatus>(
    {
        [getHomepage]: () => 'LOADING',
        [getHomepageSuccess]: () => 'SUCCESS',
        [getHomepageFailure]: () => 'FAILURE',
        [getHomepageIdle]: () => 'IDLE',
    },
    initialState.status
);

const homepage = handleActions(
    {
        [getHomepageSuccess]: (
            state,
            action: { payload: { homepage: {} } }
        ) => action.payload.homepage
    },
    {}
);

const news = handleActions(
    {
        [getHomepageSuccess]: (
            state,
            action: { payload: { news: any[] } }
        ) => action.payload.news
    },
    []
);

export const homepageReducer = combineReducers({
    status,
    homepage,
    news,
});
