import { createActions } from 'redux-actions';

const {
    getAbout,
    getAboutSuccess,
    getAboutFailure,
    getAboutIdle,
}: {
    getAbout?: any;
    getAboutSuccess?: any;
    getAboutFailure?: any;
    getAboutIdle?: any;
} = createActions(
    'GET_ABOUT',
    'GET_ABOUT_SUCCESS',
    'GET_ABOUT_FAILURE',
    'GET_ABOUT_IDLE',
);

export {
    getAbout,
    getAboutSuccess,
    getAboutFailure,
    getAboutIdle,
};
