import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { ContactsState } from './types';
import {
    getContacts,
    getContactsSuccess,
    getContactsFailure,
    getContactsIdle,
} from './actions';

export const initialState: ContactsState = {
    status: 'IDLE',
    contacts: null,
};

const status = handleActions<StateStatus>(
    {
        [getContacts]: () => 'LOADING',
        [getContactsSuccess]: () => 'SUCCESS',
        [getContactsFailure]: () => 'FAILURE',
        [getContactsIdle]: () => 'IDLE',
    },
    initialState.status
);

const contacts = handleActions(
    {
        [getContactsSuccess]: (
            state,
            action: { payload: { contacts: {} } }
        ) => action.payload.contacts
    },
    {}
);

export const contactsReducer = combineReducers({
    status,
    contacts,
});
