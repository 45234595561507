import { createActions } from 'redux-actions';

const {
    getFreight,
    getFreightSuccess,
    getFreightFailure,
    getFreightIdle,
}: {
    getFreight?: any;
    getFreightSuccess?: any;
    getFreightFailure?: any;
    getFreightIdle?: any;
} = createActions(
    'GET_FREIGHT',
    'GET_FREIGHT_SUCCESS',
    'GET_FREIGHT_FAILURE',
    'GET_FREIGHT_IDLE',
);

export {
    getFreight,
    getFreightSuccess,
    getFreightFailure,
    getFreightIdle,
};
