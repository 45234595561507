import { fork, all } from 'redux-saga/effects';

import { routerSaga } from './ducks/router/saga';

import { newsWatcher } from './ducks/news/saga';
import { policyWatcher } from './ducks/policies/saga';
import { postWatcher } from './ducks/post/saga';
import { productsWatcher } from './ducks/products/saga';
import { productWatcher } from './ducks/product/saga';
import { homepageWatcher } from './ducks/homepage/saga';
import { contactsWatcher } from './ducks/contacts/saga';
import { freightWatcher } from './ducks/freight/saga';
import { aboutWatcher } from './ducks/about/saga';
import { governanceWatcher } from './ducks/governance/saga';
import { headerWatcher } from './ducks/header/saga';
import { footerWatcher } from './ducks/footer/saga';

export default function* rootSaga() {
    yield all([
        fork(homepageWatcher),
        fork(routerSaga),

        fork(newsWatcher),
        fork(policyWatcher),
        fork(postWatcher),
        fork(productsWatcher),
        fork(productWatcher),
        fork(homepageWatcher),
        fork(contactsWatcher),
        fork(freightWatcher),
        fork(aboutWatcher),
        fork(governanceWatcher),
        fork(headerWatcher),
        fork(footerWatcher),
    ]);
}
