import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

type tProps = {
    url: string;
    lang: string;
};

export const getPolicyRequest = (props: tProps) => {

    const { url } = props;

    let locale: string = "";
    if (props && props.lang) {
        const lang: string = props.lang;
        if (lang) {
            locale += "?locale=" + lang;
        }
    }

    const requestUrl = apiUrl + url + locale;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Strapi-Response-Format': 'v4',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
