import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

type tProps = {
    lang: string;
};

export const getHomepageRequest = (props: tProps) => {

    let locale: string = "";
    if (props && props.lang) {
        const lang: string = props.lang;
        if (lang) {
            locale += "&locale=" + lang;
        }
    }

    const requestUrl =
        apiUrl +
        'homepage/'+
        '?populate[0]=Affiliates' +
        '&populate[1]=Contacts' +
        '&populate[2]=Contacts.Link' +
        '&populate[3]=Contacts.Link' +
        '&populate[4]=Content' +
        '&populate[5]=Content.Image' +
        '&populate[6]=Content.Text' +
        '&populate[7]=Content.Link' +
        '&populate[8]=HeaderStats' +
        '&populate[9]=Map' +
        '&populate[10]=Map.Affiliates' +
        '&populate[11]=Map.Link' +
        '&populate[12]=News' +
        locale;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Strapi-Response-Format': 'v4',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => {
          //console.log(data);
          return data
        });
};
