import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

export const getHeaderRequest = () => {
    const requestUrl =
        apiUrl +
        'header/?populate=Logo,Logo.LogoWhiteGreen,Logo.LogoWhiteBlack,Logo.LogoGreenBlack,Menu,Menu.SubmenuItems,Contacts';

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
