import { createActions } from 'redux-actions';

const {
    getHeader,
    getHeaderSuccess,
    getHeaderFailure,
    getHeaderIdle,
}: {
    getHeader?: any;
    getHeaderSuccess?: any;
    getHeaderFailure?: any;
    getHeaderIdle?: any;
} = createActions(
    'GET_HEADER',
    'GET_HEADER_SUCCESS',
    'GET_HEADER_FAILURE',
    'GET_HEADER_IDLE',
);

export {
    getHeader,
    getHeaderSuccess,
    getHeaderFailure,
    getHeaderIdle,
};
