import React, { useEffect, useState, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useLocation,
    Switch,
    Route,
    useHistory,
    BrowserRouter,
    useRouteMatch,
} from 'react-router-dom';
import loadable from '@loadable/component';
import cn from 'classnames';
import { hot } from 'react-hot-loader/root';
import { Header, Footer } from 'components';

import routes from 'routes';
import './App.scss';
import '../../assets/css/style.css';
import '../../assets/css/fonts.css';
import '../../assets/css/article.css';
import '../../assets/css/animate.css';
import '../../assets/css/media/1600/media.css';
import '../../assets/css/media/1000/media.css';
import '../../assets/css/media/640/media.css';
import '../../assets/css/media/340/media.css';

import Page404 from 'pages/404/404';

import { State } from 'types/redux';

import { localizations } from '../../config/config';
import { tLang, tSelectedfLanguage } from '../../types/models';

interface IWindowConfig {
    ym?: any;
}

type WindowType = Window & typeof globalThis & IWindowConfig;

export const LangContext = createContext();
type Props = {
    hostname: string;
    selectedLang: tSelectedfLanguage;
};
function App(props: Props) {
    const location = useLocation();

    const [mainClass, setMainClass] = useState<string>('');
    useEffect(() => {
        let className: string = '';
        if (location) {
            if (location.pathname === '/') {
                className = 'home';
            } else if (location.pathname.indexOf('products/') > -1) {
                className = 'white-header';
            }
        }
        setMainClass(className);
    }, [location.pathname]);

    useEffect(() => {
        if (window.scrollY > 0) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }, [location.pathname]);

    const is404 = useSelector((state: State) => state.notFound.is404);
    const selectedLang: tSelectedfLanguage =
        typeof window !== 'undefined'
            ? window.__SELECTED_LANGUAGE__
            : props.selectedLang;
    // localizations
    
    return (
        <div className={mainClass}>
            {!is404 && <Header lang={selectedLang} />}
            <div className={cn('wrapper')}>
                <LangContext.Provider value={selectedLang}>
                    <Switch>
                        {routes.map(({ fetchData, ...routeProps }, index) => {
                            return <Route key={index} {...routeProps} />;
                        })}

                        <Route path="*" exact={true} component={Page404} />
                    </Switch>
                </LangContext.Provider>
            </div>
            {!is404 && <Footer lang={selectedLang} />}
        </div>
    );
}

const Component = hot(App);

export { Component as App };
