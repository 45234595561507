import { createActions } from 'redux-actions';

const {
    getFooter,
    getFooterSuccess,
    getFooterFailure,
    getFooterIdle,
}: {
    getFooter?: any;
    getFooterSuccess?: any;
    getFooterFailure?: any;
    getFooterIdle?: any;
} = createActions(
    'GET_FOOTER',
    'GET_FOOTER_SUCCESS',
    'GET_FOOTER_FAILURE',
    'GET_FOOTER_IDLE',
);

export {
    getFooter,
    getFooterSuccess,
    getFooterFailure,
    getFooterIdle,
};
