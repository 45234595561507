import React, { useEffect, useState } from 'react';
import { imgUrl, mainUrl } from '../../config/config';
import './Share.scss';
import { useLocation } from 'react-router-dom';

export function Share() {
    const location = useLocation();
    const copyCurrentLink = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        navigator.clipboard.writeText(window.location.href);
    };

    return (
        <>
            <span>Share:</span>
            <a
                href={`https://www.linkedin.com/shareArticle?url=${mainUrl}${location.pathname}`}
                target="_blank"
            >
                <img src={imgUrl + 'article/in.svg'} alt="" />
            </a>
            <a href="#" onClick={copyCurrentLink}>
                <img src={imgUrl + 'article/link.svg'} alt="" />
            </a>
        </>
    );
}
