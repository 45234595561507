import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { FreightState } from './types';
import {
    getFreight,
    getFreightSuccess,
    getFreightFailure,
    getFreightIdle,
} from './actions';

export const initialState: FreightState = {
    status: 'IDLE',
    freight: null,
};

const status = handleActions<StateStatus>(
    {
        [getFreight]: () => 'LOADING',
        [getFreightSuccess]: () => 'SUCCESS',
        [getFreightFailure]: () => 'FAILURE',
        [getFreightIdle]: () => 'IDLE',
    },
    initialState.status
);

const freight = handleActions(
    {
        [getFreightSuccess]: (
            state,
            action: { payload: { freight: {} } }
        ) => action.payload.freight
    },
    {}
);


export const freightReducer = combineReducers({
    status,
    freight,
});
