import { call, takeEvery, put } from 'redux-saga/effects';

import {
    getContacts,
    getContactsSuccess,
    getContactsFailure,
    getContactsIdle,
} from "./actions";
import {
    getContactsRequest,
} from "./api";
//import { ActivitiesReduxType } from "./types";
import { tStrapiSingleDataResponse } from '../../../types/strapi.models';

function* getSaga(action: { payload:any }) {
    const { payload } = action;
    try {
        const contactsResult: tStrapiSingleDataResponse = yield call(
            getContactsRequest,
            payload,
        );
        if (contactsResult && contactsResult.status === "error") {
            yield put(
                getContactsFailure({
                    error: contactsResult.error,
                })
            );
        } else {
            yield put(
                getContactsSuccess({
                    contacts: contactsResult.data,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(getContactsFailure(error));
    }
}

export function* contactsWatcher() {
    yield takeEvery(getContacts.toString(), getSaga);
}
