import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

export const getNewsRequest = () => {
    const filters: string = '&pagination[pageSize]=3&sort[0]=Date:desc';

    const url = apiUrl + 'news-list/?populate=*' + filters;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
