import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

export const getPostRequest = (props: number) => {
    const { id } = props;

    const url =
        apiUrl +
        'news-list/' +
        id +
        '/?populate=Image,PostBody.Image,PostBody.AuthorPhoto,PostBody.Video,Reference';

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => {
            return data;
        });
};
