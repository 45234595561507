import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { HeaderState } from './types';
import {
    getHeader,
    getHeaderSuccess,
    getHeaderFailure,
    getHeaderIdle,
} from './actions';

export const initialState: HeaderState = {
    status: 'IDLE',
    header: null,
};

const status = handleActions<StateStatus>(
    {
        [getHeader]: () => 'LOADING',
        [getHeaderSuccess]: () => 'SUCCESS',
        [getHeaderFailure]: () => 'FAILURE',
        [getHeaderIdle]: () => 'IDLE',
    },
    initialState.status
);

const header = handleActions(
    {
        [getHeaderSuccess]: (
            state,
            action: { payload: { header: {} } }
        ) => action.payload.header
    },
    {}
);


export const headerReducer = combineReducers({
    status,
    header,
});
