import { call, takeEvery, put } from 'redux-saga/effects';

import {
    getAbout,
    getAboutSuccess,
    getAboutFailure,
    getAboutIdle,
} from "./actions";
import {
    getAboutRequest,
} from "./api";
//import { ActivitiesReduxType } from "./types";
import { tStrapiSingleDataResponse, tStrapiDataResponse } from '../../../types/strapi.models';

function* getSaga(action: { payload:any }) {
    const { payload } = action;
    try {
        const aboutResult: tStrapiSingleDataResponse = yield call(
            getAboutRequest,
            payload,
        );
        if (aboutResult && aboutResult.status === "error") {
            yield put(
                getAboutFailure({
                    error: aboutResult.error,
                })
            );
        } else {
            yield put(
                getAboutSuccess({
                    about: aboutResult.data,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(getAboutFailure(error));
    }
}

export function* aboutWatcher() {
    yield takeEvery(getAbout.toString(), getSaga);
}
