import { call, takeEvery, put } from 'redux-saga/effects';

import {
    getPolicy,
    getPolicySuccess,
    getPolicyFailure,
    getPolicyIdle,
} from "./actions";
import {
    getPolicyRequest,
} from "./api";
//import { ActivitiesReduxType } from "./types";
import { tStrapiSingleDataResponse } from '../../../types/strapi.models';

function* getSaga(action: { payload:any }) {
    const { payload } = action;
    try {
        const policyResult: tStrapiSingleDataResponse = yield call(
            getPolicyRequest,
            payload,
        );
        if (policyResult && policyResult.status === "error") {
            yield put(
                getPolicyFailure({
                    error: policyResult.error,
                })
            );
        } else {
            yield put(
                getPolicySuccess({
                    policy: policyResult.data,
                    //meta: policyResult.meta,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(getPolicyFailure(error));
    }
}

export function* policyWatcher() {
    yield takeEvery(getPolicy.toString(), getSaga);
}
