import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

export const getProductRequest = (props: any) => {

    const slug: string = props.slug;

    let filter:string = "";
    if (slug) {
        filter += "?filters[Slug][$eq]=" + slug;
    }

    const requestUrl =
        apiUrl +
        'single-products' +
        filter +
        '&populate=ProductCards,ProductCards.CardOption,Image,Reference,ProductCards.ShowLogo,ProductCards.Image';

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => {
            return data.data[0];
        });
};
