import { call, takeEvery, put } from 'redux-saga/effects';

import {
    getPost,
    getPostSuccess,
    getPostFailure,
    getPostIdle,
} from './actions';
import { getPostRequest } from './api';
//import { ActivitiesReduxType } from './types';
import {
    tStrapiSingleDataResponse,
    tStrapiResposeError,
} from '../../../types/strapi.models';

function* getSaga(action: { payload: any }) {
    const { payload } = action;
    try {
        const postResult:
            | tStrapiSingleDataResponse
            | tStrapiResposeError = yield call(getPostRequest, payload);
        if (postResult && postResult.error) {
            yield put(
                getPostFailure({
                    error: postResult.error,
                })
            );
        } else {
            yield put(
                getPostSuccess({
                    post: postResult.data,
                    //meta: postResult.meta,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(getPostFailure(error));
    }
}

export function* postWatcher() {
    yield takeEvery(getPost.toString(), getSaga);
}
