import { createSelector } from "reselect";
import { tHeaderData, tMenu, tContacts, tLogos, tSubmenu } from "../types";

const getState = (state) => state.headerReducer.header;

const headerSelector = createSelector([getState], (item) => {

    let results: tHeaderData;

    if (item) {


        let menu: tMenu[] = [];
        let contacts: tContacts;
        let logos: tLogos;

        const { attributes } = item;



        if (attributes && attributes.Menu) {

            if (attributes.Menu && attributes.Menu.length > 0) {
                attributes.Menu.forEach(menuItem => {

                    let submenu: tSubmenu[] = [];

                    if (menuItem.SubmenuItems && menuItem.SubmenuItems.length > 0) {
                        menuItem.SubmenuItems.forEach(subItem => {
                            submenu = [
                                ...submenu,
                                {
                                    id: subItem.id,
                                    title: subItem.Title,
                                    url: subItem.Url,
                                }
                            ];
                        });
                    }

                    menu = [
                        ...menu,
                        {
                            id: menuItem.id,
                            title: menuItem.Title,
                            url: menuItem.Url,
                            submenu,
                        }
                    ];

                });
            }
        }

        if (attributes && attributes.Logo) {
            logos = {
                logoWhiteGreen: attributes.Logo.LogoWhiteGreen && attributes.Logo.LogoWhiteGreen.data && attributes.Logo.LogoWhiteGreen.data.attributes && attributes.Logo.LogoWhiteGreen.data.attributes.url && attributes.Logo.LogoWhiteGreen.data.attributes.url,
                logoWhiteBlack: attributes.Logo.LogoWhiteBlack && attributes.Logo.LogoWhiteBlack.data && attributes.Logo.LogoWhiteBlack.data.attributes && attributes.Logo.LogoWhiteBlack.data.attributes.url && attributes.Logo.LogoWhiteBlack.data.attributes.url,
                logoGreenBlack: attributes.Logo.LogoGreenBlack && attributes.Logo.LogoGreenBlack.data && attributes.Logo.LogoGreenBlack.data.attributes && attributes.Logo.LogoGreenBlack.data.attributes.url && attributes.Logo.LogoGreenBlack.data.attributes.url,
            };
        }

        if (attributes && attributes.Contacts) {
            contacts = {
                title: attributes.Contacts.Title,
                url: attributes.Contacts.Url,
            };
        }

        results = {
            id: item.id,
            logos,
            menu,
            contacts,
        }
    }

    return results;
});

export default headerSelector;
