import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

type tProps = {
    lang: string;
};

export const getAboutRequest = (props: tProps) => {

    let locale: string = "";
    if (props && props.lang) {
        const lang: string = props.lang;
        if (lang) {
            locale += "&locale=" + lang;
        }
    }

    const requestUrl =
        apiUrl +
        'about-us/' +
        '?populate[0]=Stats' +
        '&populate[1]=Stats.Icon' +
        '&populate[2]=Text' +
        '&populate[3]=Leaders' +
        '&populate[4]=Leaders.Image' +
        '&populate[5]=Leaders.Leaders' +
        '&populate[6]=Leaders.Leaders.Photo' +
        '&populate[7]=Leaders.Link' +
        '&populate[8]=Team' +
        '&populate[9]=Image' +
        '&populate[10]=Team.Text' +
        locale;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Strapi-Response-Format': 'v4',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
