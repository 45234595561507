import { tLocalizations, tSelectedfLanguage, tLangValue } from 'types/models';

//export const siteUrl: string = 'https://fertistream-api.dev.deareditor.ru';
export const siteUrl: string = 'https://fertistream.com/strapi';
export const imgUrl: string = '/assets/img/';
export const imgUrlProduct: string = '/assets/img/';

let apiUrl: string = 'https://fertistream.com/strapi/api/';
//let apiUrl: string = 'https://fertistream-api.dev.deareditor.ru/api/';
//let apiUrl: string = 'https://fertistream-api-v5.dev.deareditor.ru/api/';
if (typeof window === 'undefined') {
	apiUrl = 'http://localhost:1437/api/';
} else {
	apiUrl = `https://${window.location.host}/strapi/api/`
}

export { apiUrl };
export const mainUrl = 'https://fertistream.com';
//export const mainUrl = 'https://fertistream-api-v5.dev.deareditor.ru';

export const defaultOGImageUrl = `${mainUrl}/assets/img/OG_01.png`;
export const defaultTitle = `FertiStream`;
export const defaultDescription = `We provide global markets with high-quality mineral fertilizers and world-class freight to help feed the growing population`;
export const defaultGlobalDomain = 'new.fertistream.com';

export const selectLanguage = (
    hostName: string,
    langValue: string = ''
): tSelectedfLanguage => {
    let lang: tLangValue = { title: '', value: '' };
    let langList: tLangValue[] | [] = [];
    if (localizations[hostName]) {
        const localization = localizations[hostName].languages.find(
            value => value.value === langValue
        );
        langList = localizations[hostName].languages;
        if (localization) {
            lang = localization;
        } else {
            lang = localizations[hostName].languages[0];
        }
    } else {
        lang = localizations[defaultGlobalDomain].languages[0];
        langList = localizations[defaultGlobalDomain].languages;
    }
    return { lang, langList };
};

export const localizations: tLocalizations = {
    'fertistream.com': {
        market: 'Global',
        languages: [
            {
                title: 'ENG',
                value: 'en',
                locale: 'en',
            },
        ],
    },
    'new.fertistream.com': {
        market: 'Global',
        languages: [
            {
                title: 'ENG',
                value: 'en',
                locale: 'en',
            },
        ],
    },
    'fertistream-cn.dev.deareditor.ru': {
        market: 'Mandarin',
        languages: [
            {
                title: 'MAN',
                value: 'zh',
                locale: 'zh-cn',
            },
        ],
    },
    'fertistream.dev.deareditor.ru': {
        market: 'Global',
        languages: [
            {
                title: 'ENG',
                value: 'en',
                locale: 'en',

            },
            {
                title: 'POR',
                value: 'pt-BR',
                locale: 'pt-br',
            },
        ],
    },
    localhost: {
        market: 'Global',
        languages: [
            /*{
             title: 'MAN',
             value: 'zh',
             },*/
            {
                title: 'ENG',
                value: 'en',
                locale: 'en',
            },
            {
                title: 'POR',
                value: 'pt-BR',
                locale: 'pt-br',
            },
        ],
    },
    'www.fertistream.com.br': {
        market: 'Brazil',
        languages: [
            {
                title: 'POR',
                value: 'pt-BR',
                locale: 'pt-br',
            },
        ],
    },
    'fertistream.com.br': {
        market: 'Brazil',
        languages: [
            {
                title: 'POR',
                value: 'pt-BR',
                locale: 'pt-br',
            },
        ],
    },
    'www.fertistream.com.cn': {
        market: 'Chine',
        languages: [
            {
                title: 'MAN',
                value: 'zh',
                locale: 'zh-cn',
            },
        ],
    },
    'fertistream.com.cn': {
        market: 'China',
        languages: [
            {
                title: 'MAN',
                value: 'zh',
                locale: 'zh-cn',
            },
        ],
    },
    'www.fertistream.id': {
        market: 'Indonesia',
        languages: [
            {
                title: 'IND',
                value: 'id',
                locale: 'id',
            },
        ],
    },
    'fertistream.id': {
        market: 'Indonesia',
        languages: [
            {
                title: 'IND',
                value: 'id',
                locale: 'id',
            },
        ],
    },
    'www.fertistream.my': {
        market: 'Malaysia',
        languages: [
            {
                title: 'MAL',
                value: 'ms',
                locale: 'ms-my',
            },
        ],
    },
    'fertistream.my': {
        market: 'Malaysia',
        languages: [
            {
                title: 'MAL',
                value: 'ms',
                locale: 'ms-my',
            },
        ],
    },
    'www.latam.fertistream.com': {
        market: 'LatAm',
        languages: [
            {
                title: 'SPA',
                value: 'es',
                locale: 'es',
            },
        ],
    },
    'latam.fertistream.com': {
        market: 'LatAm',
        languages: [
            {
                title: 'SPA',
                value: 'es',
                locale: 'es',
            },
        ],
    },
    'www.fertistream.asia': {
        market: 'Asia',
        languages: [
            {
                title: 'ENG',
                value: 'en-PH',
                locale: 'en',
            },
        ],
    },
    'fertistream.asia': {
        market: 'Asia',
        languages: [
            {
                title: 'ENG',
                value: 'en-PH',
                locale: 'en',
            },
        ],
    },
    'www.europe.fertistream.com': {
        market: 'Europe',
        languages: [
            {
                title: 'ENG',
                value: 'en-EU',
                locale: 'en',
            },
        ],
    },
    'europe.fertistream.com': {
        market: 'Europe',
        languages: [
            {
                title: 'ENG',
                value: 'en-EU',
                locale: 'en',
            },
        ],
    },
    'www.africa.fertistream.com': {
        market: 'Africa',
        languages: [
            {
                title: 'FRA',
                value: 'fr-SN',
                locale: 'fr',
            },
            {
                title: 'ENG',
                value: 'en-RW',
                locale: 'en',
            },
        ],
    },
    'africa.fertistream.com': {
        market: 'Africa',
        languages: [
            {
                title: 'FRA',
                value: 'fr-SN',
                locale: 'fr',
            },
            {
                title: 'ENG',
                value: 'en-RW',
                locale: 'en',
            },
        ],
    },
    'www.fertistream.md': {
        market: 'Moldove',
        languages: [
            {
                title: 'ROM',
                value: 'ro-MD',
                locale: 'ro',
            },
            {
                title: 'RUS',
                value: 'ru-MD',
                locale: 'ru',
            },
        ],
    },
    'fertistream.md': {
        market: 'Moldove',
        languages: [
            {
                title: 'ROM',
                value: 'ro-MD',
                locale: 'ro',
            },
            {
                title: 'RUS',
                value: 'ru-MD',
                locale: 'ru',
            },
        ],
    },
};
