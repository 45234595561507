import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { imgUrl, siteUrl } from '../../config/config';
import { isMobile } from 'react-device-detect';
import { getHeader } from '../../store/ducks/header/actions';
import headerSelector from '../../store/ducks/header/selectors/headerSelector';

import cn from 'classnames';

import './Header.scss';
//import Link from "react-scroll/modules/components/Link";

export function Header() {

    const dispatch = useDispatch();
    const location = useLocation();

    const [ menuOpen, menuOpenChange ] = useState<boolean>(false);
    const [ submenuOpen, setSubmenuOpen ] = useState<boolean>(false);

    const path = location.pathname;
    /* const path = location.pathname;

    const [selectValue, setSelectValue] = useState({
        value: 'ru',
        label: 'ru',
    });

    const options = [
        { value: 'ru', label: 'ru' },
        { value: 'en', label: 'en' },
    ];

    const handleChange = (e: any) => {
        setSelectValue(e);
        // dispatch(setLanguage(e));
    };

    const headerContent = useSelector(homepageSelector);*/

    const header = useSelector(headerSelector);
    const status = useSelector(state => state.headerReducer.status);

    useEffect(() => {
        dispatch(getHeader());
    }, []);

    //useEffect(() => {
    //    console.log("location: ", location);
    //}, [location]);

    return (
        <header className="header">
            {header && status && status === "SUCCESS" &&
                <div className="container">
                    <div className="header-wrapper">
                        <div className="header-logo">
                            <Link to="/">
                                <img
                                    src={siteUrl + header.logos.logoWhiteGreen}
                                    alt=""
                                    className="home-logo"
                                />
                                <img
                                    src={siteUrl + header.logos.logoGreenBlack}
                                    alt=""
                                    className="page-logo"
                                />
                                <img
                                    src={siteUrl + header.logos.logoWhiteBlack}
                                    alt=""
                                    className="green-logo"
                                />
                            </Link>
                        </div>
                        <div className={`header-menu ${menuOpen ? 'show' : ''}`}>
                            {header.menu && header.menu.length > 0 &&
                                <ul>
                                    <li className="header-mob-logo">
                                        <img
                                            src={siteUrl + header.logos.logoGreenBlack}
                                            alt=""
                                            className="header-mob-logo__img"
                                        />
                                    </li>
                                    {header.menu.map(item => (
                                        <li className={`menu-item${item.submenu.length > 0 && " menu-item-has-children" || ""}`} key={item.id}>
                                            <Link
                                                to={item.url}
                                                exact
                                                className={location && location.pathname && location.pathname.indexOf(item.url) > -1 && "disabled"}
                                                onClick={(e) => {
                                                    if (isMobile) {
                                                        if (item.submenu.length > 0) {
                                                            e.preventDefault();
                                                            setSubmenuOpen(!submenuOpen);
                                                        } else {
                                                            menuOpenChange(false);
                                                        }
                                                    }
                                                }}
                                            >
                                                {item.title}
                                            </Link>
                                            {item.submenu && item.submenu.length > 0 &&
                                                <ul className={`submenu${submenuOpen && " show" || ""}`}>
                                                    {isMobile && (
                                                        <li className="menu-item disabled">
                                                            <Link
                                                                to="/products"
                                                                onClick={() =>
                                                                    menuOpenChange(false)
                                                                }
                                                            >
                                                                All products
                                                            </Link>
                                                        </li>
                                                    )}
                                                    {item.submenu.map((subitem, index) => {
                                                        let lastChild:boolean = false;
                                                        if (index === item.submenu.length - 1) lastChild = true;
                                                        return (
                                                            <li className={`menu-item${lastChild && " menu-other-item" || ""}`} key={index}>
                                                                <Link
                                                                    to={subitem.url}
                                                                    onClick={() =>
                                                                        menuOpenChange(false)
                                                                    }
                                                                >
                                                                    {lastChild
                                                                        && <span dangerouslySetInnerHTML={{__html: subitem.title}} />
                                                                        || subitem.title
                                                                    }
                                                                    {lastChild &&
                                                                        <img
                                                                            src={imgUrl + 'circle-arrow-right.svg'}
                                                                            alt=""
                                                                        />
                                                                    }
                                                                </Link>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            }
                                        </li>
                                    ))}
                                    {isMobile && header.contacts && header.contacts.url && header.contacts.title &&
                                        <li className={`menu-item`}>
                                            <Link
                                                to={header.contacts.url}
                                                onClick={() =>
                                                    menuOpenChange(false)
                                                }
                                            >
                                                {header.contacts.title}
                                            </Link>
                                        </li>
                                    }
                                </ul>
                            }
                            <div
                                className="language"
                                style={{ opacity: 0, cursor: 'default' }}
                            >
                                <span>ENG</span>
                                <div className="languages">
                                    <div className="languages-item">DEU</div>
                                    <div className="languages-item">PT</div>
                                    <div className="languages-item">CHI</div>
                                    <div className="languages-item">ARA</div>
                                </div>
                            </div>
                        </div>
                        <div className="header-other">
                            {/* <div className="header-search">
                             <Link to="/search">
                             <span><img src={imgUrl + "search1.svg"} alt="" /></span>
                             </Link>
                             </div> */}
                            <div
                                className="language"
                                style={{ opacity: 0, cursor: 'default' }}
                            >
                                <span>ENG</span>
                                <div className="languages">
                                    <div className="languages-item">DEU</div>
                                    <div className="languages-item">PT</div>
                                    <div className="languages-item">CHI</div>
                                    <div className="languages-item">ARA</div>
                                </div>
                            </div>
                            {header.contacts && header.contacts.url && header.contacts.title &&
                                <Link to={header.contacts.url} className="callback">
                                    {header.contacts.title}
                                </Link>
                            }
                            <div
                                className={`burger-menu ${
                                    menuOpen ? 'active' : ''
                                }`}
                                onClick={() => menuOpenChange(!menuOpen)}
                            >
                                <span />
                                <span />
                                <span />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </header>
    );
}
