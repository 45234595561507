import loadable from '@loadable/component';
import { RouterFetchDataArgs } from 'types';

import { getInitdata } from './store/ducks/404/actions';
import path from 'path';

const Main = loadable(() => import('./pages/Main/Main'));
const Search = loadable(() => import('./pages/Search/Search'));
const About = loadable(() => import('./pages/About/About'));
const Contacts = loadable(() => import('./pages/Contacts/Contacts'));
const News = loadable(() => import('./pages/News/News'));
const Policies = loadable(() => import('./pages/Policies/Policies'));
const Post = loadable(() => import('./pages/Post/Post'));
const Products = loadable(() => import('./pages/Products/Products'));
const Product = loadable(() => import('./pages/Product/Product'));
const Freight = loadable(() => import('./pages/Freight/Freight'));
const Corporate = loadable(() => import('./pages/Corporate/Corporate'));
const Page404 = loadable(() => import('./pages/404/404'));

import { getHomepage } from 'store/ducks/homepage/actions';
import { getContacts } from 'store/ducks/contacts/actions';
import { getNews } from 'store/ducks/news/actions';
import { getProduct } from 'store/ducks/product/actions';
import { getProducts } from 'store/ducks/products/actions';
import { getPost } from 'store/ducks/post/actions';
import { getPolicy } from 'store/ducks/policies/actions';
import { getFreight } from 'store/ducks/freight/actions';
import { getAbout } from 'store/ducks/about/actions';
import { getGovernance } from 'store/ducks/governance/actions';
import { getFooter } from 'store/ducks/footer/actions';
import { getHeader } from 'store/ducks/header/actions';

/**
 * Routes are moved to a separate file,
 * so that you can use the asyncFetchData method on the component on the server (by url path)
 * which load all the necessary data for rendering the page.
 */

export default [
    {
        path: '/',
        component: Main,
        exact: true,
        fetchData({ dispatch, langValue }: RouterFetchDataArgs) {
            dispatch(getHomepage({ lang: langValue }));
            dispatch(getFooter({ lang: langValue }));
            dispatch(getHeader({ lang: langValue }));
        },
    },
    {
        path: '/search',
        component: Search,
        exact: true,
    },
    {
        path: '/about',
        component: About,
        exact: true,
        fetchData({ dispatch, match, langValue }: RouterFetchDataArgs) {
            dispatch(getAbout({ lang: langValue }));
            dispatch(getFooter({ lang: langValue }));
            dispatch(getHeader({ lang: langValue }));
        },
    },
    {
        path: '/contacts',
        component: Contacts,
        exact: true,
        fetchData({ dispatch, langValue }: RouterFetchDataArgs) {
            dispatch(getContacts({ lang: langValue }));
            dispatch(getFooter({ lang: langValue }));
            dispatch(getHeader({ lang: langValue }));
        },
    },
    {
        path: '/news',
        component: News,
        exact: true,
        fetchData({ dispatch, langValue }: RouterFetchDataArgs) {
            dispatch(
                getNews({
                    page: 1,
                    perPage: 6,
                    lang: langValue,
                })
            );
            dispatch(getFooter({ lang: langValue }));
            dispatch(getHeader({ lang: langValue }));
        },
    },
    {
        path: '/policies/:url?',
        component: Policies,
        exact: true,
        fetchData({ dispatch, match, langValue }: RouterFetchDataArgs) {
            dispatch(
                getPolicy({
                    url: match.params.url,
                    lang: langValue,
                })
            );
            dispatch(getFooter({ lang: langValue }));
            dispatch(getHeader({ lang: langValue }));
        },
    },
    {
        path: '/post/:id',
        component: Post,
        exact: true,
        fetchData({ dispatch, match, langValue }: RouterFetchDataArgs) {
            dispatch(getPost({ slug: match.params.id, lang: langValue }));
            dispatch(getFooter({ lang: langValue }));
            dispatch(getHeader({ lang: langValue }));
        },
    },
    {
        path: '/products',
        component: Products,
        exact: true,
        fetchData({ dispatch, match, langValue }: RouterFetchDataArgs) {
            dispatch(getProducts({ lang: langValue }));
            dispatch(getFooter({ lang: langValue }));
            dispatch(getHeader({ lang: langValue }));
        },
    },
    {
        path: '/products/:id',
        component: Product,
        exact: true,
        fetchData({ dispatch, match, langValue }: RouterFetchDataArgs) {
            dispatch(getProduct({ id: match.params.id, lang: langValue }));
            dispatch(getFooter({ lang: langValue }));
            dispatch(getHeader({ lang: langValue }));
        },
    },
    {
        path: '/freight',
        component: Freight,
        exact: true,
        fetchData({ dispatch, match, langValue }: RouterFetchDataArgs) {
            dispatch(getFreight({ lang: langValue }));
            dispatch(getFooter({ lang: langValue }));
            dispatch(getHeader({ lang: langValue }));
        },
    },
    {
        path: '/corporate-governance',
        component: Corporate,
        exact: true,
        fetchData({ dispatch, match, langValue }: RouterFetchDataArgs) {
            dispatch(getGovernance({ lang: langValue }));
            dispatch(getFooter({ lang: langValue }));
            dispatch(getHeader({ lang: langValue }));
        },
    },
];
