import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { State } from 'types';

import { notFound } from './ducks/404/reducer';

import { newsReducer } from './ducks/news/reducer';
import { policyReducer } from './ducks/policies/reducer';
import { postReducer } from './ducks/post/reducer';
import { productsReducer } from './ducks/products/reducer';
import { productReducer } from './ducks/product/reducer';
import { homepageReducer } from './ducks/homepage/reducer';
import { contactsReducer } from './ducks/contacts/reducer';
import { freightReducer } from './ducks/freight/reducer';
import { aboutReducer } from './ducks/about/reducer';
import { governanceReducer } from './ducks/governance/reducer';
import { headerReducer } from './ducks/header/reducer';
import { footerReducer } from './ducks/footer/reducer';

export default (history: History) =>
    combineReducers<State>({
        notFound,

        router: connectRouter(history),
        postReducer,
        newsReducer,
        policyReducer,

        productsReducer,
        productReducer,
        homepageReducer,
        contactsReducer,
        freightReducer,
        aboutReducer,
        governanceReducer,
        headerReducer,
        footerReducer,
    });
