import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

export const getFreightRequest = () => {
    const requestUrl =
        apiUrl +
        'freight/?populate=Contacts,Contacts.Link,Content,Content.Image,Content.ContentText,Content.ContentText.Carriers,,Content.ContentText.Carriers.Stats';

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
