import { call, takeEvery, put } from 'redux-saga/effects';

import {
    getFooter,
    getFooterSuccess,
    getFooterFailure,
    getFooterIdle,
} from "./actions";
import {
    getFooterRequest,
} from "./api";
//import { ActivitiesReduxType } from "./types";
import { tStrapiSingleDataResponse, tStrapiDataResponse } from '../../../types/strapi.models';

function* getSaga(action: { payload:any }) {
    const { payload } = action;
    try {
        const footerResult: tStrapiSingleDataResponse = yield call(
            getFooterRequest,
            payload,
        );
        if (footerResult && footerResult.status === "error") {
            yield put(
                getFooterFailure({
                    error: footerResult.error,
                })
            );
        } else {
            yield put(
                getFooterSuccess({
                    footer: footerResult.data,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(getFooterFailure(error));
    }
}

export function* footerWatcher() {
    yield takeEvery(getFooter.toString(), getSaga);
}
