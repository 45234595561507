import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { set404 } from './actions';
import { NotFoundState } from './types';

export const initialState: NotFoundState = {
    is404: false,
};

const is404 = handleActions<boolean>(
    {
        [set404]: (state, action) => action.payload,
    },
    initialState.is404
);

export const notFound = combineReducers({
    is404,
});
