import { call, takeEvery, put } from 'redux-saga/effects';

import {
    getHeader,
    getHeaderSuccess,
    getHeaderFailure,
    getHeaderIdle,
} from "./actions";
import {
    getHeaderRequest,
} from "./api";
//import { ActivitiesReduxType } from "./types";
import { tStrapiSingleDataResponse, tStrapiDataResponse } from '../../../types/strapi.models';

function* getSaga(action: { payload:any }) {
    const { payload } = action;
    try {
        const headerResult: tStrapiSingleDataResponse = yield call(
            getHeaderRequest,
            payload,
        );
        if (headerResult && headerResult.status === "error") {
            yield put(
                getHeaderFailure({
                    error: headerResult.error,
                })
            );
        } else {
            yield put(
                getHeaderSuccess({
                    header: headerResult.data,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(getHeaderFailure(error));
    }
}

export function* headerWatcher() {
    yield takeEvery(getHeader.toString(), getSaga);
}
