import { call, takeEvery, put } from 'redux-saga/effects';

import {
    getFreight,
    getFreightSuccess,
    getFreightFailure,
    getFreightIdle,
} from "./actions";
import {
    getFreightRequest,
} from "./api";
//import { ActivitiesReduxType } from "./types";
import { tStrapiSingleDataResponse, tStrapiDataResponse } from '../../../types/strapi.models';

function* getSaga(action: { payload:any }) {
    const { payload } = action;
    try {
        const freightResult: tStrapiSingleDataResponse = yield call(
            getFreightRequest,
            payload,
        );
        if (freightResult && freightResult.status === "error") {
            yield put(
                getFreightFailure({
                    error: freightResult.error,
                })
            );
        } else {
            yield put(
                getFreightSuccess({
                    freight: freightResult.data,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(getFreightFailure(error));
    }
}

export function* freightWatcher() {
    yield takeEvery(getFreight.toString(), getSaga);
}
