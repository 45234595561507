import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { FooterState } from './types';
import {
    getFooter,
    getFooterSuccess,
    getFooterFailure,
    getFooterIdle,
} from './actions';

export const initialState: FooterState = {
    status: 'IDLE',
    footer: null,
};

const status = handleActions<StateStatus>(
    {
        [getFooter]: () => 'LOADING',
        [getFooterSuccess]: () => 'SUCCESS',
        [getFooterFailure]: () => 'FAILURE',
        [getFooterIdle]: () => 'IDLE',
    },
    initialState.status
);

const footer = handleActions(
    {
        [getFooterSuccess]: (
            state,
            action: { payload: { footer: {} } }
        ) => action.payload.footer
    },
    {}
);


export const footerReducer = combineReducers({
    status,
    footer,
});
