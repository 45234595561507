import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

export const getHomepageRequest = () => {
    const requestUrl =
        apiUrl +
        'homepage/?populate=Affiliates,Contacts,Contacts.Link,Content,Content.Image,Content.Text,Content.Link,HeaderStats,Map,Map.Affiliates,Map.Link';

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
