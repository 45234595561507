import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

type tProps = {
    page: number;
    perPage: number;
    lang: string;
};

export const getNewsRequest = (props: tProps) => {

    const { page, perPage } = props;

    let filters = '';
    if (perPage) {
        filters += '&pagination[pageSize]=' + perPage;
    }
    if (page) {
        filters += '&pagination[page]=' + page;
    }

    let locale: string = "";
    if (props && props.lang) {
        const lang: string = props.lang;
        if (lang) {
            locale += "&locale=" + lang;
        }
    }

    filters += '&sort[0]=Date:desc';

    const url = apiUrl + 'news-list/' +
        '?populate[0]=Image' +
        '&populate[1]=Image328x220' +
        filters +
        locale;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Strapi-Response-Format': 'v4',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
