import { call, takeEvery, put } from 'redux-saga/effects';

import {
    getGovernance,
    getGovernanceSuccess,
    getGovernanceFailure,
    getGovernanceIdle,
} from "./actions";
import {
    getGovernanceRequest,
} from "./api";
//import { ActivitiesReduxType } from "./types";
import { tStrapiSingleDataResponse, tStrapiDataResponse } from '../../../types/strapi.models';

function* getSaga(action: { payload:any }) {
    const { payload } = action;
    try {
        const governanceResult: tStrapiSingleDataResponse = yield call(
            getGovernanceRequest,
            payload,
        );
        if (governanceResult && governanceResult.status === "error") {
            yield put(
                getGovernanceFailure({
                    error: governanceResult.error,
                })
            );
        } else {
            yield put(
                getGovernanceSuccess({
                    governance: governanceResult.data,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(getGovernanceFailure(error));
    }
}

export function* governanceWatcher() {
    yield takeEvery(getGovernance.toString(), getSaga);
}
