import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

export const getNewsRequest = (props: {}) => {
    const { page, perPage } = props;

    let filters = '';
    if (perPage) {
        filters += '&pagination[pageSize]=' + perPage;
    }
    if (page) {
        filters += '&pagination[page]=' + page;
    }

    filters += '&sort[0]=Date:desc';

    const url = apiUrl + 'news-list/?populate=*' + filters;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
