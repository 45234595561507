import { call, takeEvery, put } from 'redux-saga/effects';

import {
    getNews,
    getNewsSuccess,
    getNewsFailure,
    getNewsIdle,
} from './actions';
import {
    getNewsRequest,
} from './api';
import { ActivitiesReduxType } from './types';

function* getSaga(action: { payload:any }) {
    const { payload } = action;
    try {
        const newsResult: ActivitiesReduxType = yield call(
            getNewsRequest,
            payload
        );
        if (newsResult && newsResult.status === 'error') {
            yield put(
                getNewsFailure({
                    error: newsResult.error,
                })
            );
        } else {
            yield put(
                getNewsSuccess({
                    news: newsResult.data,
                    meta: newsResult.meta,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(getNewsFailure(error));
    }
}

export function* newsWatcher() {
    yield takeEvery(getNews.toString(), getSaga);
}
