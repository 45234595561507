import * as React from 'react';
import Helmet from 'react-helmet';
import {
    defaultOGImageUrl,
    defaultTitle,
    defaultDescription,
    mainUrl,
} from 'config/config';
import { useLocation } from 'react-router-dom';
type Props = {
    title?: string;
    description?: string;
    image?: string;
    url?: string;
    canonical?: string;
};

const cutTags = (text: string = '') => {
    return text.replace(/<\/?.+?>/gi, '');
};

const prepareData = ({ title, description, image, url, canonical }: Props) => {
    return {
        title: title && cutTags(title),
        description: description && cutTags(description).substr(0, 250),
        image,
        url,
        canonical,
    };
};

function PageMeta(props: Props) {
    const { title, description, image, url, canonical } = prepareData(props);
    const location = useLocation();
    return (
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={canonical ? canonical : url} />
            <meta property="og:title" content={title} />
            <meta
                property="og:url"
                content={url || `${mainUrl}${location.pathname}`}
            />
            <meta property="twitter:title" content={title} />

            {description && <meta name="description" content={description} />}
            {description && (
                <meta property="og:description" content={description} />
            )}
            {description && (
                <meta property="twitter:description" content={description} />
            )}
            {image && <meta property="og:image" content={image} />}
        </Helmet>
    );
}

PageMeta.defaultProps = {
    title: defaultTitle,
    description: defaultDescription,
    image: defaultOGImageUrl,
    url: '',
};

export { PageMeta };
