import { createActions } from 'redux-actions';

const {
    getGovernance,
    getGovernanceSuccess,
    getGovernanceFailure,
    getGovernanceIdle,
}: {
    getGovernance?: any;
    getGovernanceSuccess?: any;
    getGovernanceFailure?: any;
    getGovernanceIdle?: any;
} = createActions(
    'GET_GOVERNANCE',
    'GET_GOVERNANCE_SUCCESS',
    'GET_GOVERNANCE_FAILURE',
    'GET_GOVERNANCE_IDLE',
);

export {
    getGovernance,
    getGovernanceSuccess,
    getGovernanceFailure,
    getGovernanceIdle,
};
