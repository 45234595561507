export const siteUrl: string = 'https://fertistream.com/strapi';
export const imgUrl: string = '/assets/img/';
export const imgUrlProduct: string = '/assets/img/';

let apiUrl: string = 'https://fertistream.com/strapi/api/';
if (typeof window === 'undefined') {
    apiUrl = 'http://localhost:1437/api/';
}

export { apiUrl };
export const mainUrl = 'https://fertistream.com';

export const defaultOGImageUrl = `${mainUrl}/assets/img/OG_01.png`;
export const defaultTitle = `FertiStream`;
export const defaultDescription = `We provide global markets with high-quality mineral fertilizers and world-class freight to help feed the growing population`;
