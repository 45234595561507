import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

export const getPolicyRequest = (props: {}) => {
    const { url } = props;

    const requestUrl = apiUrl + url;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
