import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { imgUrl, siteUrl } from "../../config/config";
import { getFooter } from '../../store/ducks/footer/actions';
import footerSelector from '../../store/ducks/footer/selectors/footerSelector';
import "./Footer.scss";

export function Footer() {

	const dispatch = useDispatch();
	const footer = useSelector(footerSelector);
	const status = useSelector(state => state.footerReducer.status);

	useEffect(() => {
		dispatch(getFooter());
	}, []);

    return (
    	<>
			{footer && status && status === "SUCCESS" &&
				<footer className="footer">
					<div className="container">
						<div className="footer-wrapper">
							<div className="footer-col footer-col-logo">
								<div className="footer-logo">
									<img src={siteUrl + footer.logo} alt=""/>
								</div>
								<div
									className="footer-excerpt"
									dangerouslySetInnerHTML={{__html: footer.description}}
								/>
							</div>
							<div className="footer-col footer-address">
								{footer.address && footer.address.title && footer.address.description &&
									<>
										<strong>{footer.address.title}</strong>
										<div dangerouslySetInnerHTML={{__html: footer.address.description}}/>
									</>
								}
							</div>
							<div className="footer-col">
								{footer.phone && footer.phone.title && footer.phone.description &&
									<>
										<strong>{footer.phone.title}</strong>
										<div dangerouslySetInnerHTML={{__html: footer.phone.description}} /><br />
									</>
								}
								{footer.email && footer.email.title && footer.email.description &&
									<>
										<strong>{footer.email.title}</strong>
										<div dangerouslySetInnerHTML={{__html: footer.email.description}} />
									</>
								}
							</div>
							{/*
							 <div className="footer-subscribe">
							 <form>
							 <div className="form-subscribe__field">
							 <input type="email" name="email-subscribe" id="email-subscribe" placeholder="Enter your email to get the latest news..." />
							 </div>
							 <button className="form-subscribe__send">Subscribe</button>
							 </form>
							 </div>
							 */}
						</div>
						<div className="copyright">
							<div className="copyright-text">
								{footer.copyright}
							</div>
							<div className="footer-menu">
								{footer.menu && footer.menu.length > 0 &&
									<ul>
										{footer.menu.map(item=> (
											<li key={item.id}>
												<Link to={item.url}>{item.title}</Link>
											</li>
										))}
									</ul>
								}
							</div>
							<div className="footer-soc">
								{
									/*
									 <a href="#">
									 <img src={imgUrl + "snap.svg"} alt="" />
									 </a>
									 <a href="#">
									 <img src={imgUrl + "youtube.svg"} alt="" />
									 </a>
									 <a href="#">
									 <img src={imgUrl + "facebook.svg"} alt="" />
									 </a>
									 <a href="#">
									 <img src={imgUrl + "inst.svg"} alt="" />
									 </a>
									 <a href="#">
									 <img src={imgUrl + "twitter.svg"} alt="" />
									 </a>
									 */
								}
								{footer.socials && footer.socials.length > 0 && footer.socials.map(item => (
									<a href={item.url} target="_blank" key={item.id}>
										<img src={siteUrl + item.icon} alt=""/>
									</a>
								))}
							</div>
						</div>
					</div>
				</footer>
			}
		</>
    );
}
