import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { AboutState } from './types';
import {
    getAbout,
    getAboutSuccess,
    getAboutFailure,
    getAboutIdle,
} from './actions';

export const initialState: AboutState = {
    status: 'IDLE',
    about: null,
};

const status = handleActions<StateStatus>(
    {
        [getAbout]: () => 'LOADING',
        [getAboutSuccess]: () => 'SUCCESS',
        [getAboutFailure]: () => 'FAILURE',
        [getAboutIdle]: () => 'IDLE',
    },
    initialState.status
);

const about = handleActions(
    {
        [getAboutSuccess]: (
            state,
            action: { payload: { about: {} } }
        ) => action.payload.about
    },
    {}
);


export const aboutReducer = combineReducers({
    status,
    about,
});
