import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { imgUrl, siteUrl } from '../../config/config';
import { isMobile } from 'react-device-detect';
import { getHeader } from '../../store/ducks/header/actions';
import headerSelector from '../../store/ducks/header/selectors/headerSelector';

import cn from 'classnames';

import './Header.scss';
//import Link from "react-scroll/modules/components/Link";
import { tLang, tLangValue, tSelectedfLanguage } from '../../types/models';

export function Header(props: { lang: tSelectedfLanguage }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const [menuOpen, menuOpenChange] = useState<boolean>(false);
    const [submenuOpen, setSubmenuOpen] = useState<boolean>(false);
    const [languagesOpen, setLanguagesOpen] = useState<boolean>(false);
    const [languages, setLanguages] = useState<tLangValue[]>([]);

    const path = location.pathname;
    /* const path = location.pathname;

    const [selectValue, setSelectValue] = useState({
        value: 'ru',
        label: 'ru',
    });

    const options = [
        { value: 'ru', label: 'ru' },
        { value: 'en', label: 'en' },
    ];

    const handleChange = (e: any) => {
        setSelectValue(e);
        // dispatch(setLanguage(e));
    };

    const headerContent = useSelector(homepageSelector);*/

    const header = useSelector(headerSelector);
    const status = useSelector(state => state.headerReducer.status);
    const lang: tSelectedfLanguage = props.lang;

    useEffect(() => {
        dispatch(getHeader({ lang: lang.lang.value }));
    }, [lang]);

    useEffect(() => {
        if (lang && lang.langList && lang.langList.length > 0) {
            setLanguages(lang.langList);
        }
    }, [lang]);

    const changeLanguage = (item) => {
        if (item && item.value) {
            fetch("/api/setLang/" + item.value)
                .then(response => response.json())
                .then(data => {
                    //console.log(data);
                    if (location.pathname.indexOf('/post/') > -1) {
                        window.location.href = '/news';
                    } else if (location.pathname.indexOf('/products/') > -1) {
                        window.location.href = '/products';
                    } else {
                        window.location.reload();
                    }
                })
                .catch(error => console.error(error));
        }
    };

    return (
        <header className="header">
            {header && status && status === 'SUCCESS' && (
                <div className="container">
                    <div className="header-wrapper">
                        <div className="header-logo">
                            <Link to="/">
                                <img
                                    src={siteUrl + header.logos.logoWhiteGreen}
                                    alt=""
                                    className="home-logo"
                                />
                                <img
                                    src={siteUrl + header.logos.logoGreenBlack}
                                    alt=""
                                    className="page-logo"
                                />
                                <img
                                    src={siteUrl + header.logos.logoWhiteBlack}
                                    alt=""
                                    className="green-logo"
                                />
                            </Link>
                        </div>
                        <div
                            className={`header-menu ${menuOpen ? 'show' : ''}`}
                        >
                            {header.menu && header.menu.length > 0 && (
                                <ul>
                                    <li className="header-mob-logo">
                                        <img
                                            src={
                                                siteUrl +
                                                header.logos.logoGreenBlack
                                            }
                                            alt=""
                                            className="header-mob-logo__img"
                                        />
                                    </li>
                                    {header.menu.map(item => (
                                        <li
                                            className={`menu-item${
                                                (item.submenu.length > 0 &&
                                                    ' menu-item-has-children') ||
                                                ''
                                            }`}
                                            key={item.id}
                                        >
                                            {item.type === "inner"
                                                &&
                                                <Link
                                                    to={item.url}
                                                    className={
                                                            location &&
                                                            location.pathname &&
                                                            location.pathname.indexOf(
                                                                item.url
                                                            ) > -1 &&
                                                            'disabled'
                                                        }
                                                    onClick={e => {
                                                        const winWidth =
                                                            window.screen.width;
                                                        if (winWidth <= 576) {
                                                            if (
                                                                item.submenu
                                                                    .length > 0
                                                            ) {
                                                                e.preventDefault();
                                                                setSubmenuOpen(
                                                                    !submenuOpen
                                                                );
                                                            } else {
                                                                menuOpenChange(
                                                                    false
                                                                );
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {item.title}
                                                </Link>
                                                ||
                                                <a href={item.url} target="_blank">{item.title}</a>
                                            }
                                            {item.submenu &&
                                                item.submenu.length > 0 && (
                                                    <ul
                                                        className={`submenu${
                                                            (submenuOpen &&
                                                                ' show') ||
                                                            ''
                                                        }`}
                                                    >
                                                        <li className="menu-item disabled menu-item-mobile">
                                                            <Link
                                                                to="/products"
                                                                onClick={() =>
                                                                    menuOpenChange(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                {item.title}
                                                            </Link>
                                                        </li>
                                                        {item.submenu.map(
                                                            (
                                                                subitem,
                                                                index
                                                            ) => {
                                                                let lastChild: boolean = false;
                                                                if (
                                                                    index ===
                                                                    item.submenu
                                                                        .length -
                                                                        1
                                                                )
                                                                    lastChild = true;
                                                                return (
                                                                    <li
                                                                        className={`menu-item${
                                                                            (lastChild &&
                                                                                ' menu-other-item') ||
                                                                            ''
                                                                        }`}
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {subitem.type === "inner"
                                                                            &&
                                                                            <Link
                                                                                to={subitem.url}
                                                                                onClick={() => menuOpenChange(false)}
                                                                            >
                                                                                {(lastChild && (
                                                                                    <span
                                                                                        dangerouslySetInnerHTML={{ __html: subitem.title}}/>
                                                                                )) || subitem.title}
                                                                                {lastChild && (
                                                                                    <img
                                                                                        src={
                                                                                            imgUrl +
                                                                                            'circle-arrow-right.svg'
                                                                                        }
                                                                                        alt=""
                                                                                    />
                                                                                )}
                                                                            </Link>
                                                                            ||
                                                                            <a href={subitem.url} target="_blank">{subitem.title}</a>
                                                                        }
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                )}
                                        </li>
                                    ))}
                                    {header.contacts &&
                                        header.contacts.url &&
                                        header.contacts.title && (
                                            <li
                                                className={`menu-item mobile-contacts menu-item-mobile`}
                                            >
                                                <Link
                                                    to={header.contacts.url}
                                                    onClick={() =>
                                                        menuOpenChange(false)
                                                    }
                                                >
                                                    {header.contacts.title}
                                                </Link>
                                            </li>
                                        )}
                                    {languages && languages.length > 1 &&
                                        <li className={`menu-item menu-item-mobile menu-item-has-children`}>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setLanguagesOpen(!languagesOpen);
                                                }}
                                            >
                                                {lang && lang.lang && lang.lang.title}
                                            </a>
                                            <ul className={`submenu${languagesOpen && " show" || ""}`}>
                                                {languages &&
                                                languages.length > 1 &&
                                                languages.map(item => (
                                                    <li
                                                        key={item.value}
                                                        className="languages-item"
                                                        onClick={() => changeLanguage(item)}
                                                    >
                                                        <a href="#">{item.title}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    }
                                </ul>
                            )}
                        </div>
                        <div className="header-other">
                            {/* <div className="header-search">
                             <Link to="/search">
                             <span><img src={imgUrl + "search1.svg"} alt="" /></span>
                             </Link>
                             </div> */}
                            <div
                                className={`desktop language${
                                    (languages.length > 1 && ' _show') || ''
                                }`}
                            >
                                {languages.length > 1 && (
                                    <span>
                                        {lang && lang.lang && lang.lang.title}
                                    </span>
                                )}
                                <div
                                    className={`languages${
                                        (languages.length > 1 && ' _show') || ''
                                    }`}
                                >
                                    {languages &&
                                        languages.length > 1 &&
                                        languages.map(item => (
                                            <div
                                                key={item.value}
                                                className="languages-item"
                                                onClick={() => changeLanguage(item)}
                                            >
                                                {item.title}
                                            </div>
                                        ))}
                                </div>
                            </div>
                            {header.contacts &&
                                header.contacts.url &&
                                header.contacts.title && (
                                    <Link
                                        to={header.contacts.url}
                                        className="callback"
                                    >
                                        {header.contacts.title}
                                    </Link>
                                )}
                            <div
                                className={`burger-menu ${
                                    menuOpen ? 'active' : ''
                                }`}
                                onClick={() => menuOpenChange(!menuOpen)}
                            >
                                <span />
                                <span />
                                <span />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </header>
    );
}
