import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

type tProps = {
    lang: string;
};

export const getNewsRequest = (props: tProps) => {

    let locale: string = "";
    if (props && props.lang) {
        const lang: string = props.lang;
        if (lang) {
            locale += "&locale=" + lang;
        }
    }

    const filters: string = '&pagination[pageSize]=3&sort[0]=Date:desc';

    const url = apiUrl + 'news-list/?populate=*' + filters + locale;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Strapi-Response-Format': 'v4',
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
