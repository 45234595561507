import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { ProductsState } from './types';
import {
    getProduct,
    getProductSuccess,
    getProductFailure,
    getProductIdle,
} from './actions';

export const initialState: ProductsState = {
    status: 'IDLE',
    product: null,
};

const status = handleActions<StateStatus>(
    {
        [getProduct]: () => 'LOADING',
        [getProductSuccess]: () => 'SUCCESS',
        [getProductFailure]: () => 'FAILURE',
        [getProductIdle]: () => 'IDLE',
    },
    initialState.status
);

const product = handleActions(
    {
        [getProductSuccess]: (
            state,
            action: { payload: { product: {} } }
        ) => action.payload.product
    },
    {}
);

export const productReducer = combineReducers({
    status,
    product,
});
