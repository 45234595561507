import { call, takeEvery, put } from 'redux-saga/effects';

import {
    getHomepage,
    getHomepageSuccess,
    getHomepageFailure,
    getHomepageIdle,
} from "./actions";
import {
    getHomepageRequest,
    getNewsRequest,
} from "./api";
//import { ActivitiesReduxType } from "./types";
import { tStrapiSingleDataResponse, tStrapiDataResponse } from '../../../types/strapi.models';

function* getSaga(action: { payload:any }) {
    const { payload } = action;
    try {
        const homepageResult: tStrapiSingleDataResponse = yield call(
            getHomepageRequest,
            payload,
        );
        const homepageNews: tStrapiDataResponse = yield call(
            getNewsRequest,
            payload,
        );
        if (homepageResult && homepageResult.status === "error") {
            yield put(
                getProductFailure({
                    error: homepageResult.error,
                })
            );
        } else {
            yield put(
                getHomepageSuccess({
                    homepage: homepageResult.data,
                    news: homepageNews.data,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(getHomepageFailure(error));
    }
}

export function* homepageWatcher() {
    yield takeEvery(getHomepage.toString(), getSaga);
}
