import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

export const getFooterRequest = () => {
    const requestUrl =
        apiUrl +
        'footer/?populate=Address,Logo,Menu,Phone,Email,Socials,Socials.Icon';

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
