import { createActions } from 'redux-actions';

const {
    getPolicy,
    getPolicySuccess,
    getPolicyFailure,
    getPolicyIdle,
}: {
    getPolicy?: any;
    getPolicySuccess?: any;
    getPolicyFailure?: any;
    getPolicyIdle?: any;
} = createActions(
    'GET_POLICY',
    'GET_POLICY_SUCCESS',
    'GET_POLICY_FAILURE',
    'GET_POLICY_IDLE',
);

export {
    getPolicy,
    getPolicySuccess,
    getPolicyFailure,
    getPolicyIdle,
};
