import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { PolicyState } from './types';
import {
    getPolicy,
    getPolicySuccess,
    getPolicyFailure,
    getPolicyIdle,
} from './actions';

export const initialState: PolicyState = {
    status: 'IDLE',
    policy: null,
};

const status = handleActions<StateStatus>(
    {
        [getPolicy]: () => 'LOADING',
        [getPolicySuccess]: () => 'SUCCESS',
        [getPolicyFailure]: () => 'FAILURE',
        [getPolicyIdle]: () => 'IDLE',
    },
    initialState.status
);

const policy = handleActions(
    {
        [getPolicySuccess]: (
            state,
            action: { payload: { policy: {} } }
        ) => action.payload.policy
    },
    {}
);

export const policyReducer = combineReducers({
    status,
    policy,
});
