import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

type tProps = {
    lang: string;
};

export const getFreightRequest = (props: tProps) => {

    let locale: string = "";
    if (props && props.lang) {
        const lang: string = props.lang;
        if (lang) {
            locale += "&locale=" + lang;
        }
    }

    const requestUrl =
        apiUrl +
        'freight/' +
        '?populate[0]=Contacts' +
        '&populate[1]=Contacts.Link' +
        '&populate[2]=Content' +
        '&populate[3]=Content.Image' +
        '&populate[4]=Content.ContentText' +
        '&populate[5]=Content.ContentText.Carriers' +
        '&populate[6]=Content.ContentText.Carriers.Stats' +
        locale;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Strapi-Response-Format': 'v4',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
