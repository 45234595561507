import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

export const getContactsRequest = () => {
    const requestUrl =
        apiUrl +
        'contact/?populate=Affiliate,Affiliate.Image,Affiliate.MapLink,Affiliate.MapLink.Icon,Affiliate.Management,Affiliate.Management.Image';

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
