import { createActions } from 'redux-actions';

const {
    getProduct,
    getProductSuccess,
    getProductFailure,
    getProductIdle,
}: {
    getProduct?: any;
    getProductSuccess?: any;
    getProductFailure?: any;
    getProductIdle?: any;
} = createActions(
    'GET_PRODUCT',
    'GET_PRODUCT_SUCCESS',
    'GET_PRODUCT_FAILURE',
    'GET_PRODUCT_IDLE',
);

export {
    getProduct,
    getProductSuccess,
    getProductFailure,
    getProductIdle,
};
