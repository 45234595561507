import { createActions } from 'redux-actions';

const {
    getContacts,
    getContactsSuccess,
    getContactsFailure,
    getContactsIdle,
}: {
    getContacts?: any;
    getContactsSuccess?: any;
    getContactsFailure?: any;
    getContactsIdle?: any;
} = createActions(
    'GET_CONTACTS',
    'GET_CONTACTS_SUCCESS',
    'GET_CONTACTS_FAILURE',
    'GET_CONTACTS_IDLE',
);

export {
    getContacts,
    getContactsSuccess,
    getContactsFailure,
    getContactsIdle,
};
