import { createActions } from 'redux-actions';

const {
    getHomepage,
    getHomepageSuccess,
    getHomepageFailure,
    getHomepageIdle,
}: {
    getHomepage?: any;
    getHomepageSuccess?: any;
    getHomepageFailure?: any;
    getHomepageIdle?: any;
} = createActions(
    'GET_HOMEPAGE',
    'GET_HOMEPAGE_SUCCESS',
    'GET_HOMEPAGE_FAILURE',
    'GET_HOMEPAGE_IDLE',
);

export {
    getHomepage,
    getHomepageSuccess,
    getHomepageFailure,
    getHomepageIdle,
};
