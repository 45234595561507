import { createActions } from 'redux-actions';

const {
    getProducts,
    getProductsSuccess,
    getProductsFailure,
    getProductsIdle,
}: {
    getProducts?: any;
    getProductsSuccess?: any;
    getProductsFailure?: any;
    getProductsIdle?: any;
} = createActions(
    'GET_PRODUCTS',
    'GET_PRODUCTS_SUCCESS',
    'GET_PRODUCTS_FAILURE',
    'GET_PRODUCTS_IDLE',
);

export {
    getProducts,
    getProductsSuccess,
    getProductsFailure,
    getProductsIdle,
};
