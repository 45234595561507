import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { StateStatus } from 'types/models';
import { ActivitiesReduxType, ActivitiesState } from './types';
import {
    getNews,
    getNewsSuccess,
    getNewsFailure,
    getNewsIdle,
} from './actions';

export const initialState: ActivitiesState = {
    status: 'IDLE',
    activities: null,
    uid: null,
    statusAdd: null,
    statusDelete:null,
    error: null,
    activitiesId: null,
    activitiesIdTime: null,
    activitiesBooking: null,
};

const status = handleActions<StateStatus>(
    {
        [getNews]: () => 'LOADING',
        [getNewsSuccess]: () => 'SUCCESS',
        [getNewsFailure]: () => 'FAILURE',
        [getNewsIdle]: () => 'IDLE',
    },
    initialState.status
);

const news = handleActions(
    {
        [getNewsSuccess]: (
            state,
            action: { payload: { news: [] } }
        ) => action.payload.news,
    },
    initialState.activities
);
const pagination = handleActions(
    {
        [getNewsSuccess]: (
            state,
            action: { payload: { news: {} } }
        ) => action.payload.meta,
    },
    {}
);

export const newsReducer = combineReducers({
    status,
    news,
    pagination,
});
